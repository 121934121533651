<template>
    <v-switch :false-value="0"
              :input-value="getInitialValue"
              :label="getLabel" :loading="loading"
              :true-value="1" class="my-auto pa-0"
              :color="getColor"
              :error="isRejected"
              hide-details @change="verifyField"/>
</template>

<script>
export default {
    name: "KurccVerificationToggle",
    props: {
        fieldName: {
            required: true,
            type: String
        },
        initialValue: {
            required: true
        },
        siteId: {
            required: true,
            type: String
        },
        branchId: {
            type: String
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        getLabel() {
            if (this.initialValue === null)
                return 'Unverified'
            else if (this.isUnverified)
                return 'Unverified'
            else if (this.isVerified)
                return 'Verified'
            else if (this.isRejected)
                return 'Rejected'
            else return ''
        },
        getInitialValue() {
            if (this.isUnverified || this.isRejected)
                return 0
            else if (this.isVerified)
                return 1

            return 0
        },
        getColor() {
            return !this.isRejected ? "success" : "error"
        },
        isUnverified() {
            return this.initialValue === null
        },
        isVerified() {
            return this.initialValue?.is_verified === true
        },
        isRejected() {
            return this.initialValue?.is_verified === false
        }
    },
    methods: {
        verifyField(value) {
            this.$emit('change', value)
            this.loading = true
            this.$store.dispatch('verifyField', {
                field_name: this.fieldName,
                site_id: this.siteId,
                branch_id: this.branchId,
                is_verified: value,
                description: value ? null : this.initialValue?.description
            }).then(value => {
                this.$emit('update:initialValue', value)
            }).finally(() => this.loading = false)
        }
    }
}
</script>
